@import "src/styles/shared.module";

.item {
  border: none;
  background-color: #F4F4F4;
  border-radius: 0.4rem;
  padding: 0.6rem 1.2rem;
  text-align: center;

  color: #001535;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  cursor: pointer;
  border: 1px solid #F4F4F4;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    padding: 1rem 2rem;
  }
}

.active {
  color: var(--colors__purple);
  background-color: rgba(94, 70, 245, 0.05);
  border: 1px solid #5E46F5;
}