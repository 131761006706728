.backdrop {
  background-color: rgba(0, 21, 53, 0.70);
}

.container {
  max-width: 64rem;

  z-index: 1;
  padding: 0;
}

.header,
.footer {
  flex: 0 0 auto;
}

.content {
  padding: 0;
}
