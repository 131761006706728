@import "src/styles/shared.module";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include media-breakpoint-up(lg) {
    gap: 1rem;
  }
}

.content {
  display: none;

  @include media-breakpoint-up(lg) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.content__open {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

.aside__nav__item {
  border: none;
  text-decoration: none;
  width: 100%;
  padding: 1.5rem;
  background-color: #F4F4F4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transition: background-color 0.2s ease-in-out;

  span {
    color: #525E72;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 140%;
  }
}

.active {
  background: rgba(94, 70, 245, 0.05);

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg path {
    stroke: #5E46F5;
  }

  span {
    color: #5E46F5;
  }
}

.item__orange {
  background-color: rgba(255, 95, 69, 0.05);
  span {
    color: var(--colors__orange);
  }

  svg path {
    stroke: #FF5F45;
  }

  span {
    color: #FF5F45;
  }
}

.button__content {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.arrow {
  width: 2.5rem;
  height: 2.5rem;
}

.arrowOpen {
  transform: rotate(180deg);
}