.wrapper {
  text-decoration: none;

  width: 100%;

  display: flex;
  align-items: center;
  gap: 1rem;

  background: #009D4A;

  height: 4rem;
  padding: 0 1rem;
  border-radius: 0.5rem;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.icon__wrapper {
  padding-top: 0.2rem;
  height: 100%;
  padding-inline-end: 1rem;
  border-inline-end: 1px solid rgba(0, 0, 0, 0.25);
}

.icon {
  width: 1.8rem;
  height: 1.8rem;
}

.text {
  color: var(--colors__white);
  font-size: 1.6rem;
}