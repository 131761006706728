@import "src/styles/shared.module";

.wrapper {
  max-width: 128rem;
  width: 100%;
  height: 100%;

  margin: 0 auto;
  padding-bottom: 5rem;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  padding: 0 2rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 3rem;
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  flex-grow: 1;

  @include media-breakpoint-up(md) {
    gap: 3rem;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #001535;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 130%;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.note {
  color: #001535;
  font-size: 1.2rem;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }
}

.skeleton {
  &__header {
    width: 100%;
    height: 7rem;

    margin: 3rem 2.4rem;

    @include media-breakpoint-up(md) {
      height: 8rem;
      margin: 3rem 0;
    }
  }

  &__info {
    width: 100%;
    height: 20rem;

    @include media-breakpoint-up(md) {
      width: 40rem;
      height: 43rem;
    }
  }

  &__formheader {
    width: 100%;
    height: 2rem;

    @include media-breakpoint-up(md) {
      height: 3rem;
    }
  }

  &__form {
    width: 100%;
    height: 55rem;

    @include media-breakpoint-up(md) {
      height: 55rem;
    }
  }
}