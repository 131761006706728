@import "src/styles/shared.module";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.label {
  font-size: 1.2rem;
  font-weight: 400;
  color: #525E72;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

.container {
  width: 100%;
  background-color: var(--colors__gray);
  border: 1px solid var(--colors__gray);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding-inline-end: 2rem;

  @include media-breakpoint-up(md) {
    padding-inline-end: 2.5rem;
  }
}

.editClicked {
  border: 1px solid #D5D5D5;
}

.singeInput {
  width: 100%;
  background-color: var(--colors__gray);
  border: none;
  border-radius: 8px;
}

.input {
  flex-basis: 100%;
  font-weight: 400;
  height: 100%;

  padding: 1.5rem 2rem;

  outline: none;

  color: #001535;
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  border: none;

  &__error {
    border: 1px solid #E52836;
  }

  @include media-breakpoint-up(md) {
    padding: 2rem 2.5rem;
    font-size: 1.4rem;
  }
}

.error {
  color: #E52836;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

.edit {
  border: none;
  color: #5E46F5;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;

  transition: opacity 0.1s ease-in-out;

  &__clicked {
    opacity: 0;
  }
}