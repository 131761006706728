@import "src/styles/shared.module";

.header {
  margin: 3rem 2.4rem;

  padding: 0;

  @include media-breakpoint-up(md) {
    margin: 3rem 0;
  }
}

.withoutPadding {
  padding-bottom: 0;
}

.header__wrapper {
  border: 1px solid #D5D5D5;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--colors__white);
  border-radius: 15px;
  padding: 1.2rem;

  @include media-breakpoint-up(lg) {
    background-color: var(--colors__white);
    padding: 1.3rem 3rem;
  }
}

.header__wrapper__menu {
  z-index: 3;
  position: fixed;
  left: 50%;
  top: 3.5rem;
  border: none;

  width: 80%;

  transform: translateX(-50%);
}

.navigation {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.navigation__list {
  align-items: center;
  gap: 3.5rem;

  display: none;

  @include media-breakpoint-up(xl) {
    display: flex;
  }
}

.logo {
  fill: var(--colors__dark_blue);
  width: 4.8rem;
  height: 4.7rem;
}

.link {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  border: none;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--colors__purple);
  }
}

.common .langs {
  display: none;

  @include media-breakpoint-up(xl) {
    display: flex;
  }
}

.lang {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--colors__dark_blue);
  border: 0;
  cursor: pointer;
}

.activeLang {
  border-bottom: 2px solid var(--colors__orange);
}

.common {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 3rem;
  }
}

.burger {
  position: relative;
  gap: 0.3rem;

  width: 3.2rem;
  height: 3.2rem;

  border: 1px solid #D5D5D5;
  background-color: #F4F4F4;
  border-radius: 50%;

  span {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translateX(-50%);
    width: 1.5rem;
    height: 2px;
    background-color: #525E72;
  }

  span:nth-child(1) {
    top: 34%;
  }

  span:nth-child(3) {
    top: 60%;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.burgerOpen {
  span {
  }

  span:nth-child(1) {
    left: 40%;
    transform-origin: initial;
    transform: rotate(-45deg) translateX(-40%);
  }

  span:nth-child(3) {
    left: 40%;
    transform-origin: initial;
    transform: rotate(45deg) translateX(-40%);
  }

  span:nth-child(2) {
    display: none;
  }
}