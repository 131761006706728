@import "src/styles/shared.module";

.wrapper {
  display: none;
  width: 100%;

  align-items: center;
  padding-bottom: 0.8rem;
  gap: 6rem;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.item {
  width: 100%;

  &:nth-child(1) {
    max-width: 40rem;
  }

  &:nth-child(2) {
    max-width: 18.5rem;
  }

  &:nth-child(3) {
    max-width: 18.5rem;
  }

  &:nth-child(4) {
    max-width: 18.5rem;
  }

  &:nth-child(5) {
    max-width: 18.5rem;
  }

  &:nth-child(6) {
    max-width: 8.4rem;
  }
}

.text {
  color: #001535;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 140%;
}