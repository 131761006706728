.overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #F4F4F4;
}