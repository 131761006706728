@import "src/styles/shared.module";

.wrapper {
  display: flex;
  gap: 3rem;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: unset;
  }
}

.aside {
  max-width: 30rem;
  width: 100%;
  background: #F4F4F4;

  padding: 5.8rem 3rem;

  display: none;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  @include media-breakpoint-up(xl) {
    padding: 5.8rem 9rem;
    max-width: 46.7rem;
  }

  &__title {
    color: #001535;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 130%;
    padding-inline-start: 1.5rem;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: #D5D5D5;
  }

  &__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__item {
      text-decoration: none;
      width: 100%;

      padding: 1.5rem;
      background-color: inherit;
      border: 1px solid transparent;
      border-radius: 8px;

      display: flex;
      align-items: center;
      gap: 1.5rem;

      transition: background-color 0.2s ease-in-out;

      &:hover {
        background: rgba(255, 95, 69, 0.05);
      }

      span {
        color: #525E72;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 140%;
      }
    }

    .active {
      border: 1px solid #FF5F45;
      background: rgba(255, 95, 69, 0.05);

      svg path {
        stroke: #FF5F45;
      }

      span {
        color: #FF5F45;
      }
    }
  }
}

.mobileNav {
  margin-bottom: 1rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(lg) {
    display: none;
  }


  &__title {
    color: #001535;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 130%;
  }

  &__item {
    text-decoration: none;
    width: 100%;

    padding: 1.5rem;
    background-color: inherit;
    border: 1px solid transparent;
    border-radius: 8px;

    display: flex;
    align-items: center;
    gap: 1.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background: rgba(255, 95, 69, 0.05);
    }

    span {
      color: #525E72;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .active {
    border: 1px solid #FF5F45;
    background: rgba(94, 70, 245, 0.05);

    svg path {
      stroke: #FF5F45;
    }

    span {
      color: #FF5F45;
    }
  }
}

.main {
  width: 100%;

  padding: 0 1rem 4rem;

  @include media-breakpoint-up(lg) {
    width: 70%;

    padding-inline-end: 6rem;
    padding-bottom: 8rem;
  }
}

.container {
  width: 100%;
}
