  @import "src/styles/shared.module";

.wrapper {
  flex-basis: 100%;

  @include media-breakpoint-up(md) {
    flex-basis: 40%;
  }
}

.input {
  max-width: 40.6rem;
  width: 100%;
}

.list {
  padding: 2rem;
  border-radius: 0.6rem;
  background: #F4F4F4;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 40.6rem;

  max-height: 26rem;
  overflow-y: scroll;
}

.item {
  width: 100%;

  text-align: start;
  color: #001535;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 140%;

  border: none;
  padding-bottom: 1rem;
  border-bottom: 1px solid #D5D5D5;

  cursor: pointer;

  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.selected {
  margin-top: 1rem;

  max-width: 40.6rem;
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 0.5rem;
}

.selected__item {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  border-radius: 5px;
  background: #F4F4F4;
  padding: 1rem 2rem;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.selected__item__text {
  color: #001535;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 140%;
}

.closeIcon {
  cursor: pointer;

  border: none;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.selectedPayment {
    font-size: 1.2rem;
    font-weight: 400;
    color: #525E72;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }
}