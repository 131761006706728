@import "src/styles/shared.module";

.container {
  width: 24rem;

  @include media-breakpoint-up(md) {
    width: 30rem;
  }
}

.character {
  border: none;
  font-size: 1.4rem;
  border-radius: 8px;

  color: #fff;
  background-color: #222;
  box-shadow: 0 2px 0 #444;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

.character__selected {
  outline: 2px solid #4CA76A;
}