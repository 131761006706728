@import "src/styles/shared.module";

.wrapper {
  max-width: 128rem;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

.content {
  padding: 0 2rem;

  h1 {
    font-size: 3.2rem;
    text-align: center;

    margin-bottom: 3.2rem;
  }

  p, span, li {
    font-size: 1.4rem;
    text-align: justify;
  }

  p, span {
    margin-bottom: 2.4rem;
  }

  li {
    margin-bottom: 1.6rem;

    margin-left: 1rem;
  }
}