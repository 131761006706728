.wrapper {
  background-color: var(--colors__white);
  padding: 5rem 3rem;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #01B853;
  border-radius: 50%;

  padding: 1rem;
}

.icon {

  width: 3rem;
  height: 3rem;

  path {
    stroke: var(--colors__white);
  }
}

.text {
  color: #01B853;
  font-size: 2rem;
  font-weight: 600;
}