.root {
  background-color: #F4F4F4;
  border-radius: 0.4rem;
}

.circular {
  border-radius: 50%;
}

.withChildren {
  background-color: #333333;

  & .root {
    background-color: #3A3A3A;
  }
}

.rounded {
  border-radius: 2rem;
}
