@import "src/styles/shared.module";

.item {
  width: 100%;
  padding: 1.6rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;

  border: 1px solid #D5D5D5;
  border-radius: 10px;

  @include media-breakpoint-up(md) {
    border-radius: 12px;
    padding: 2rem;

  }
}

.item__header {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
}

.item__title {
  color: #001535;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 130%;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.item__btns {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  @include media-breakpoint-up(md) {
    gap: 1rem;
  }
}

.item__btns .btn {
  min-width: unset;
  padding: 0.6rem 1rem;
  font-size: 1.2rem;
  border-radius: 5px;

  @include media-breakpoint-up(md) {
    padding: 0.8rem 1.5rem;
    font-size: 1.6rem;
  }
}

.item__btns .btn__edit {
  background-color: inherit;
  color: var(--colors__purple);
  border: 1px solid var(--colors__purple);

  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: var(--colors__purple);
    color: var(--colors__white);
  }
}

.item__btns .btn__remove {
  background-color: #F4F4F4;
  color: #525E72;
  border: 1px solid #F4F4F4;

  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.item__wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.item__content {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  @include media-breakpoint-up(md) {
    width: 40%;
  }
}


.item__content__title {
  color: #525E72;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

.item__content__text {
  color: #001535;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}