@import "src/styles/shared.module";

.wrapper {
  padding: 0 2.4rem;

  margin-top: 2rem;

  @include media-breakpoint-up(md) {
    padding: 0;
    margin-top: 3rem;
  }
}

.skeleton {
  width: 100%;

  &__header {
    width: 100%;
    height: 4rem;
    margin-bottom: 0.8rem;
  }

  &__item {
    margin-bottom: 1rem;

    width: 100%;
    height: 9rem;
  }
}