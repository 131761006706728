@import "src/styles/shared.module";

.wrapper {
  width: 100%;

  padding: 1.6rem;
  border-radius: 10px;

  background: #F4F4F4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    padding: 3rem;
    border-radius: 15px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__title {
    font-weight: 600;
  }
}

.btn {
  align-self: flex-end;
}