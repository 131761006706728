@import "src/styles/shared.module";

.wrapper {
  background-color: #1f1f1f;
  border-radius: 2px;

  padding: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  @include media-breakpoint-up(md) {
    width: 64rem;
    height: 64rem;
  }
}

.trovoLogo {
  width: 6rem;
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    width: 10rem;
  }
}

.title {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--colors__white);

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.btn {
  border-radius: 1rem;
  background: #4CA76A;

  padding: 1.4rem 2.5rem;

  color: #FFF;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  opacity: 0;
  visibility: hidden;

  &:hover {
    opacity: 0.8;
  }

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }

  &__visible {
    opacity: 1;
    visibility: visible;
  }
}