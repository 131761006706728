@import "src/styles/shared.module";

.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid #D5D5D5;

  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 6rem;
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
    flex-direction: row;
    gap: 6rem;
    width: 100%;
  }
}

.agency {
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 2rem;
    max-width: 40rem;
    width: 100%;
  }

  &__icon {
    background-color: #F4F4F4;
    border-radius: 4px;
    padding: 1.65rem 1.45rem;
    text-align: center;

    color: #FF5F45;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      padding: 1.5rem 2rem;
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 130%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      gap: 0.5rem;
    }
  }

  &__title {
    color: #001535;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      align-items: center;
      gap: 0.5rem;
      flex-direction: row;
    }
  }

  &__text__divider {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;

      color: #525E72;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 130%;
    }
  }

  &__text {
    color: #525E72;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.fx {
  margin-top: 1.5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    max-width: 18.5rem;
    width: 100%;
  }
}

.commission {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    max-width: 18.5rem;
    width: 100%;
  }
}

.item__mobile__title {
  color: #525E72;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.item__mobile__content {
  color: #001535;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    line-height: 130%;
  }
}

.rightSide {
  width: 40%;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    max-width: 32.9rem;
    width: 100%;

    flex-direction: row;
    gap: 6rem;
    justify-content: space-between;
  }
}

.payout {
  display: flex;
  flex-direction: column;
  text-align: end;
  width: 100%;

  &:nth-child(4) {
    max-width: 18.5rem;
  }

  @include media-breakpoint-up(md) {
    text-align: start;
  }

  &__item {
    color: #525E72;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
      line-height: 140%;
    }
  }
}

.payment {
  display: flex;
  align-self: flex-end;

  @include media-breakpoint-up(md) {
    align-self: flex-start;
  }

  .selectBtn {
    background-color: inherit;
    border-color: var(--colors__orange);
    color: var(--colors__orange);

    padding: 0.6rem 1rem;
    font-size: 1.2rem;

    @include media-breakpoint-up(md) {
      padding: 1rem 2.1rem;
      font-size: 1.6rem;
    }
  }
}