@import "src/styles/shared.module";

.wrapper {
  max-width: 60rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include media-breakpoint-up(md) {
    gap: 2rem;
  }
}

.description {
  color: #525E72;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}