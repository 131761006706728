@import "src/styles/shared.module";

.wrapper {
  max-width: 128rem;
  width: 100%;
  height: 100%;

  margin: 0 auto;
  padding-bottom: 5rem;
}

.content {
  flex: 1 0 auto;

  margin-top: 6rem;
}