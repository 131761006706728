@import "src/styles/shared.module";

.wrapper {
  width: 100%;
  display: flex;

  margin-top: 4rem;

  align-items: flex-end;
  gap: 1rem;

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    margin-bottom: -6.5rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: -4rem;
  }
}

.content {
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include media-breakpoint-up(lg) {
    gap: 1.5rem;
  }
}

.title {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 130%;

  span {
    font-size: inherit;
    color: var(--colors__text_orange);
  }

  @include media-breakpoint-up(lg) {
    font-size: 4rem;
    font-weight: 600;
    line-height: 130%;
  }

  @include media-breakpoint-up(xl) {
    font-size: 6rem;
  }
}

.description {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  color: #525E72;

  @include media-breakpoint-up(lg) {
    font-size: 1.6rem;
    font-weight: 400;
  }

  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}

.icon {
  display: none;

  @include media-breakpoint-up(lg) {
    width: 35rem;
    display: block;
  }

  @include media-breakpoint-up(xl) {
    width: 45rem;
  }
}
