@import "src/styles/shared.module";


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 100%;
  padding: 1.6rem;
  border-radius: 10px;

  &__opened {
    background: #F4F4F4;
  }

  @include media-breakpoint-up(md) {
    padding: 3rem;
    border-radius: 15px;
  }
}

.header {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;


  @include media-breakpoint-up(md) {
    align-items: center;
  }
}

.gems {
  color: var(--colors__orange);

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 130%;


  @include media-breakpoint-up(md) {
    align-self: center;
    font-size: 2rem;
    line-height: 140%;
  }
}

.header__content {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-grow: 1;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    justify-content: unset;
  }
}

.content__header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    border-bottom: 1px solid #D5D5D5;
    padding-bottom: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
}

.content__row {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  text-align: end;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: unset;
  }
}

.content__header__item {
  color: #525E72;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;

  max-width: 10.8rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }

  .semi {
    color: #525E72;

    font-size: 1.2rem;
    font-weight: 400;
    line-height: 140%;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.content__row__item {
  color: var(--colors__orange);

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;

  max-width: 10.8rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

.header__common {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
}

.common__date {
  color: #525E72;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

.btn {
  padding: 0.8rem 1.5rem;
  color: var(--colors__orange);
  background: inherit;
  border-color: var(--colors__orange);

  &:hover {
    opacity: 0.8;
  }
}

.close {
  border-color: #D5D5D5;
}

.content {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
}

.content__info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 4rem;
  }
}

.info__column {
  width: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-basis: 50%;
  }
}

.info__item {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #D5D5D5;
}

.info__item__title {
  color: #525E72;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

.info__item__content {
  color: #001535;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}
