@import "src/styles/shared.module";

.footer {
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--colors__dark_blue);

  padding: 2rem 2.4rem;

  @include media-breakpoint-up(xl) {
    padding: 5rem 6rem;
  }
}

.container {
  max-width: 128rem;
  width: 100%;
  margin: 0 auto;
  color: var(--colors__white);


  display: flex;
  flex-direction: column;
  gap: 2rem;


}

.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2.3rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: unset;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
      gap: 3rem;
      align-items: center;
    }
  }
}

.logo {
  fill: var(--colors__white);
  width: 3.6rem;
  height: 3.6rem;

  @include media-breakpoint-up(md) {
    width: 5rem;
    height: 5rem;
  }

  @include media-breakpoint-up(xl) {
    width: 6.7rem;
    height: 6.7rem;
  }
}

.login {
  align-self: flex-start;
  color: var(--colors__white);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-decoration: underline;
  border: none;

  cursor: pointer;

  @include media-breakpoint-up(md) {
    align-self: flex-end;
  }
}

.divider {
  display: none;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);;

  @include media-breakpoint-up(lg) {
    display: unset;
  }
}

.bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: unset;
  }
}

.rightsText {
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 3.5rem;
    align-items: center;
  }

  &__text {
    color: rgba(255, 255, 255, 0.50);
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      line-height: 130%;
    }
  }

  &__privacy {
    color: #FFF;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      line-height: 130%;
    }
  }
}

.mailLink {
  display: block;

  color: var(--colors__white);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.6rem 1.2rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.10);

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    padding: 0.8rem 2rem;
  }
}