@import "src/styles/shared.module";

.wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    gap: 0.8rem;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  text-decoration: none;

  @include media-breakpoint-up(md) {
    gap: 0.8rem;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  background-color: #F4F4F4;
  border-radius: 50%;

  @include media-breakpoint-up(md) {
    width: 3rem;
    height: 3rem;
  }
}

.icon {
  width: 1.12rem;
  height: 1.12rem;

  path {
    stroke: var(--colors__orange);
  }

  @include media-breakpoint-up(md) {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.name {
  color: var(--colors__orange);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

.divider {
  display: none;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;
  color: var(--colors__dark_blue);

  @include media-breakpoint-up(md) {
    display: block;
    font-size: 1.4rem;
  }
}

.logout {
  display: none;
  border: none;
  cursor: pointer;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;
  color: var(--colors__dark_blue);

  @include media-breakpoint-up(md) {
    display: block;
    font-size: 1.4rem;
  }
}