@import "src/styles/shared.module";

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    max-width: 15rem;
  }
}

.label {
  color: rgba(82, 94, 114, 0.60);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 140%;
}

.input {
  width: 100%;
  max-height: 4.8rem;
  display: flex;
  padding: 1.4rem 2.5rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-radius: 0.8rem;
  background: #F4F4F4;

  cursor: pointer;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.text {
  color: #001535;

  width: 80%;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.icon {
  width: 2rem;
  height: 2rem;

  &__open {
    transform: rotate(180deg);
  }
}

.popover {
  width: 20rem;
}

.list {
  max-height: 21rem;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.item {
  padding: 1rem 1rem;
  color: #001535;
  border-radius: 8px;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  border: none;

  cursor: pointer;

  &:hover {
    background-color: rgba(236, 236, 236, 0.6);
  }
}

.closeWrapper {
  width: 1.5rem;
  height: 1.5rem;

  border: none;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.close {
  width: 1.5rem;
  height: 1.5rem;

  padding-top: 0.2rem;
}