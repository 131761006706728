@import "src/styles/shared.module";

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 3rem;

  margin-top: 4rem;

  @include media-breakpoint-up(md) {
    margin-top: 10rem;
  }
}

.title {
  color: #001535;

  font-size: 2rem;
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
}

.content {
  width: 100%;
}

.filters {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}