.mobileMenu {
  box-sizing: border-box;

  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 2;
  background-color: var(--colors__white);
  padding: 2rem 1rem;

  top: 0;
  left: 0;

  display: none;
}

.isOpen {
  display: flex;
}

.wrapper {
  width: 100%;
  height: 96%;
  background-color: var(--colors__dark_blue);

  border-radius: 15px;

  padding: 1.2rem;
}

.mobileMenuWrapper {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-height: 80%;
  height: 80%;
  gap: 1.5rem;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

.languageToggle {
  margin-bottom: 1.1rem;
}

.link {
  color: var(--colors__white);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.mailLink {
  display: block;
  color: var(--colors__white);
  text-decoration: none;
  padding: 0.6rem 1.2rem;
  border-radius: 100rem;
  background: rgba(255, 255, 255, 0.10);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  span {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.50);
  }
}

.rightsText {
  color: rgba(255, 255, 255, 0.50);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 150%;

  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.privacyLink {
  color: var(--colors__white);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 150%;
}


.logout {
  text-align: start;

  border: none;
  cursor: pointer;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;
  color: var(--colors__white);

  margin-bottom: 3rem;
}