@import "src/styles/shared.module";

.container {
  width: 100%;
  padding: 4rem 5rem;
  border-radius: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

.header {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #001535;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 130%;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.logo {
  width: 6rem;
  height: 6rem;
}

.closeWrapper {
  padding: 0.64rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: 5px;
  border: 1px solid #D5D5D5;
}

.modalTitle {
  color: #001535;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 130%;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.form {
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .inp {
    width: 100%;
  }

  .btn {
    margin-top: 1.5rem;
    align-self: flex-start;
  }
}