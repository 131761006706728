@import "imports.module";
@import "shared.module";
@import "mixins.module";

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Light.woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Medium.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-SemiBold.woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraBold.woff2");
  font-weight: 800;
}

:root {
  font-size: 10px;

  --colors__orange: #{getColorVarValue("orange")};
  --colors__white: #{getColorVarValue("white")};
  --colors__gray: #{getColorVarValue("gray")};
  --colors__purple: #{getColorVarValue("purple")};
  --colors__dark_blue: #{getColorVarValue("dark_blue")};
  --colors__text_dark: #{getColorVarValue("text_dark")};
  --colors__text_gray: #{getColorVarValue("text_gray")};
  --colors__text_orange: #{getColorVarValue("text_orange")};
}

*,
*::after,
*::before {
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  color: var(--colors__text_dark);

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}