@import "src/styles/shared.module";

.wrapper {
  background-color: #FEFEFE;;

  padding: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    width: 38rem;
  }
}

.logo {
  width: 4.7rem;
  height: 4.7rem;

  @include media-breakpoint-up(md) {
    align-self: flex-start;
  }
}

.title {
  color: #001535;
  font-weight: 500;
  text-align: center;
  font-size: 2.4rem;

  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    text-align: unset;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 3rem;
}

.footer {
  width: 100%;
  padding-top: 3rem;
  border-top: 1px solid #D6D2D2;

  display: flex;
  align-items: center;
  justify-content: center;
}

.agencyLogin {
  color: #001535;
  font-weight: 500;
  text-align: center;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
