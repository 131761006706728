@import "src/styles/shared.module";

.wrapper {
  max-width: 128rem;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  height: 80vh;
}

.content {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.title {
  color: #C55048;

  text-align: center;
  font-size: 5rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @include media-breakpoint-up(md) {
    font-size: 7rem;
  }
}

.description {
  margin-bottom: 1rem;
  font-weight: 500;
}

.button {
  text-decoration: none;
  background-color: var(--colors__orange);
  color: var(--colors__white);

  padding: 1rem 2rem;
  border-radius: 8px;

  font-size: 1.2rem;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }
}