@import "src/styles/shared.module";

.error {
  margin: 1.5rem 0;
  border-radius: 8px;
  padding: 2rem;
  background-color: #F4F4F4;

  border: 1px solid #D5D5D5;

  &__text {
    color: var(--colors__dark_blue);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }

  &__link {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--colors__dark_blue);

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }
}