@import "src/styles/shared.module";

.wrapper {
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 2px;

  padding: 4.5rem 1rem 15rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    width: 45rem;
    align-items: flex-start;
    padding: 4.5rem 3rem 22.5rem;
  }
}

.title {
  color: var(--colors__white);
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.description {
  color: #C3BFBF;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: start;
  }
}

.trovolink {
  color: #4CA76A;
}

.content {
  width: 100%;
  margin-top: 2.5rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    color: #C3BFBF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.item {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
  }
}

.placeholder {
  padding: 1rem 0.6rem;

  background: #757575;

  display: flex;
  align-items: center;
  gap: 0.8rem;

  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 26rem;
    min-width: 26rem;
  }
}

.text {
  color: #C3BFBF;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sendBtn {
  width: 100%;

  border-radius: 0.5rem;
  background: #07B362;
  padding: 1.2rem 1.7rem;

  color: #FFF;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;

  cursor: pointer;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.icon__google {
  width: 3rem;
}