@import "src/styles/shared.module";

.button {
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;

  min-width: 10rem;

  cursor: pointer;
  color: var(--colors__white);
  background-color: var(--colors__orange);
  border-radius: 0.8rem;
  border: 1px solid;
  outline: none;

  padding: 1rem 1.5rem;
  font-size: 1.2rem;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    padding: 1.5rem 2rem;
  }

  &__disabled {
    opacity: 0.7;

    &:hover {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}

.primary {
  background: var(--colors__purple);

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: inherit;
    color: var(--colors__purple);
  }
}

.secondary {
  background: var(--colors__orange);

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: inherit;
    color: var(--colors__orange);
  }
}

.small {
  padding: 0.7rem 1.5rem;
  font-size: 1.2rem;
}