@import "src/styles/shared.module";

.modalContainer {
  border-radius: 15px;

  max-width: 60rem;
  width: 100%;
}

.wrapper {
  padding: 1.6rem;
  background: #FFFFFF;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    padding: 4rem 5rem;
    gap: 2rem;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  color: #E52836;

  font-size: 1.6rem;
  font-weight: 600;
  line-height: 130%;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.description {
  color: #525E72;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 140%;
}

.closeWrapper {
  padding: 0.34rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: 5px;
  border: 1px solid #D5D5D5;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 1.5rem;
  }
}

.buttons .outlined {
  color: #5E46F5;
  background: inherit;
  text-decoration: none;
}