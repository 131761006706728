@import "src/styles/shared.module";

.iconWrapper {
  border: none;
  cursor: pointer;

  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.8;
  }
}

.icon {
  width: 4rem;
  height: 4rem;

  path {
    stroke: #001535;
  }
}

.whiteIcon  {
  path {
    stroke: var(--colors__white);
  }
}

.popover {
  width: 15rem;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}

.lang {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;

  font-size: 1.4rem;
  font-weight: 600;
  color: var(--colors__dark_blue);
  border: 0;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #F4F4F4;
  }
}

.activeLang {
  color: var(--colors__orange);
}