@import "src/styles/shared.module";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}