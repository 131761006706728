@import "src/styles/shared.module";

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding-inline-start: 2.5rem;

  @include media-breakpoint-up(md) {
    gap: 2.5rem;

    padding-inline-start: 0;
  }
}

.sectionTitle {
  color: var(--colors__dark_blue);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 2.8rem;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
    gap: 1.5rem;
  }
}

.title {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    color: #525E72;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1.5rem;

  overflow-x: scroll;

  @include media-breakpoint-up(md) {
    gap: 1rem;
    overflow-x: unset;
    padding-bottom: 0;
  }
}

.skeleton {
  &__title {
    width: 20rem;
    height: 2rem;

    @include media-breakpoint-up(md) {
      width: 40rem;
      height: 3rem;
    }
  }

  &__subtitle {
    display: none;

    @include media-breakpoint-up(md) {
      width: 6rem;
      height: 4rem;
    }
  }

  &__item {
    width: 5rem;
    height: 3rem;

    @include media-breakpoint-up(md) {
      width: 7rem;
      height: 4rem;
    }
  }
}
