.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid #D5D5D5;

  &__text {
    color: #001535;

    font-size: 2rem;
    font-weight: 400;
    line-height: 150%;
  }

  &__btn {
    border: none;
    background: inherit;
    color: var(--colors__purple);
  }
}