@import "src/styles/shared.module";

.aqua {
  color: #525E72;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    line-height: 140%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1.6rem;
  border-radius: 10px;
  background: #F4F4F4;

  @include media-breakpoint-up(md) {
    gap: 3rem;
    padding: 3rem;
    border-radius: 20px;

    max-width: 40.7rem;
    height: fit-content;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
    gap: 2rem;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.65rem 1.45rem;
  border-radius: 0.4rem;
  background: #FFFFFF;

  color: #FF5F45;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 2rem;
    font-size: 2.2rem;
    font-weight: 500;
  }
}

.header__text {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    gap: 0.5rem;
  }
}

.header__title {
  color: #001535;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
    font-weight: 600;
  }
}

.header__info {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: var(--, #525E72);

    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
      line-height: 130%;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
    flex-direction: column;
    gap: 3rem;
  }
}

.payments {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 45%;

  @include media-breakpoint-up(md) {
    width: 100%;
    gap: 1rem;
  }
}

.content__title {
  color: #525E72;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    line-height: 140%;
  }
}

.content__text {
  list-style: inside;

  color: #001535;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.content__other {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 3rem;
  }
}

.content__other__item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    @include media-breakpoint-up(md) {
      width: 100%;
      gap: 1rem;
    }
}