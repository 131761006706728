@import "src/styles/shared.module";

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    gap: 3rem;
  }
}

.valid__error {
  border-color: #E52836;

  .gems__input__span {
    color: #E52836;
  }

  .gems__input__input {
    color: #E52836;
  }
}

.error {
  text-align: end;

  color: #E52836;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.final__wrapper {
  padding: 2rem;
  border: 1px solid #D5D5D5;
  border-radius: 8px;

  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 2rem;
  }
}

.final__amount {
  border-bottom: none;
}

.field__input {
  text-align: end;
  border: none;
  outline: none;

  color: #001535;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.field__input::-webkit-outer-spin-button,
.field__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form__field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #D5D5D5;

  @include media-breakpoint-up(md) {
    padding-bottom: 1rem;
  }

  &:last-child {
    border-bottom: none;
  }
}

.field__title {
  color: rgba(82, 94, 114, 0.60);

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.field__content {
  color: #001535;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.gems__input {
  border-color: var(--colors__orange);

  &__span {
    color: var(--colors__text_orange);
    font-weight: 500;
  }

  &__input {
    color: var(--colors__text_orange);
    font-weight: 600;
    line-height: 200%;
  }
}