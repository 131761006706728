.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 2.4rem;
}