@import "src/styles/shared.module";

.wrapper {
  width: 100%;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2.5rem;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
    max-width: 90rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

.inp {
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 40.7rem;
  }
}

.btn {
  margin-top: 3rem;

  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
}