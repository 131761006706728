@import "src/styles/shared.module";

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 3rem;

  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 6rem;
  }
}

.header {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 1rem;
  border-bottom: 1px solid #D5D5D5;

  cursor: pointer;
}

.title {
  color: #001535;

  font-size: 2rem;
  font-weight: 400;
  line-height: 150%;

  &__open {
    color: #FF5F45;
  }
}

.arrowIcon {
  &__open {
    transform: rotate(180deg);

    path {
      stroke: var(--colors__orange);
    }
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 3rem;
  }
}

.inp {
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-basis: 40%;
  }

  button {
    color: var(--colors__orange);
  }
}

.btn {
  margin-top: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;

  .label {
    font-size: 1.2rem;
    font-weight: 400;
    color: #525E72;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }
  }
}