@import "src/styles/shared.module";

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    gap: 3rem;
  }
}

.form {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 2rem;
  }
}

.form__field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #D5D5D5;

  @include media-breakpoint-up(md) {
    padding-bottom: 1rem;
  }
}

.field__title {
  color: rgba(82, 94, 114, 0.60);

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.field__content {
  color: #001535;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.select {
  max-width: 10rem;

  padding: 1rem 3rem;

  color: #001535;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
    max-width: 20rem;

  };
}

.select__item {
  color: #001535;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 140%;
  }
}

.streamer__balance {
  span {
    color: var(--colors__orange);
  }
}

